$('#datatable-stock').dataTable({
  "pageLength": 50,
  dom:"<'row'<'col-sm-5'l><'col-sm-5 right'f><'qrbutton col-sm-2 text-right'>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-12'ip>>"
});
$("div.qrbutton").html("<button name='button' type='submit' class='btn btn-success' id='list_qr_btn'>QR出力</button>");

$(function(){
  $('#list_qr_btn').on('click',function(){
    var ids=$('[name=ids]:checked').map(
      function(){
        return encodeURIComponent($(this).val());
      }
    ).get().join(",");
    window.location.href = '/stocks/list_qr' + '?ids='+ids;
  });
});

$('#datatable-stock-orders').dataTable({
  order: [ [ 2, "desc" ] ],
  "pageLength": 50
});

$('#datatable-stock-movement-histories').dataTable({
  order: [ [ 0, "desc" ] ],
  "pageLength": 50
});