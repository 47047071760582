$('#datatable-create-inventory').dataTable({
  "pageLength": 50,
  dom:"<'row'<'col-sm-5'l><'col-sm-5 right'f><'createbutton col-sm-2 text-right'>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-12'ip>>"
});
$("div.createbutton").html("<button name='button' type='button' class='btn btn-success' id='create_btn' data-toggle='modal' data-target='#createModal'>リスト作成</button>");

$('input[name="checkall"]').change(function(){
    var table = $('#datatable-create-inventory').dataTable();
    var that = this;
    table.$('input[name="ids[]"]').map(function() {
        $(this).prop('checked',that.checked)
        if(that.checked == true){
            $(this).addClass('selected')
        }else{
            $(this).removeClass('selected')
        }
    });
});

$('#inv-submit').on('click', function(){
    var form = $(this).parents('form');
    var table = $('#datatable-create-inventory').dataTable();
    var rows = $(table.$('input[name="ids[]"]').map(function() {
        return $(this).prop("checked") ? $(this).val() : null;
    }));

    $.each(rows, function(index, rowId) {
        $('<input>').attr({
            'type': 'hidden',
            'name': 'ids[]',
            'value': rowId
        }).appendTo(form);
    });
    form.submit();
});
