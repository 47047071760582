$('#datatable-transport').dataTable({
  pageLength: 50,
  order: [],
  dom:"<'row'<'col-sm-5'l><'col-sm-5 right'f><'printbutton col-sm-2 text-right'>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-12'ip>>"
});
$("div.printbutton").html("<button name='button' type='submit' class='btn btn-success' formtarget='blank' id='list_print_btn'>伝票出力</button>");

$('#datatable-order-products').dataTable({
  pageLength: 50,
  order: []
});

$('input[name="checkall-transport"]').change(function(){
  var table = $('#datatable-transport').dataTable();
  var that = this;
  table.$('input[name="ids[]"]').map(function() {
      $(this).prop('checked',that.checked)
      if(that.checked == true){
          $(this).addClass('selected')
      }else{
          $(this).removeClass('selected')
      }
  });
});

$('#list_print_btn').on('click', function(){
  $('input:hidden[name="ids[]"]').remove();
  var form = $(this).parents('form');
  var table = $('#datatable-transport').dataTable();
  var rows = $(table.$('input[name="ids[]"]').map(function() {
      return $(this).prop("checked") ? $(this).val() : null;
  }));

  $.each(rows, function(index, rowId) {
      $('<input>').attr({
          'type': 'hidden',
          'name': 'ids[]',
          'value': rowId
      }).appendTo(form);
  });
  form.submit();
});

$('select#order_customer_id').change(function() {
  const customer_info = $('option:selected', this).data('info');
  if (customer_info == undefined) return;

  $('input#exampleCustomerCompanyName').val(customer_info.company_name);
  $('input#exampleCustomerFamilyName').val(customer_info.family_name);
  $('input#exampleCustomerFirstName').val(customer_info.first_name);
  $('input#exampleCustomerAddressPostalCode').val(customer_info.address_postal_code);
  $('input#exampleCustomerAddressPrefecture').val(customer_info.address_prefecture);
  $('input#exampleCustomerAddressCity').val(customer_info.address_city);
  $('input#exampleCustomerAddressBlock').val(customer_info.address_block);
  $('input#exampleCustomerAddressBuilding').val(customer_info.address_building);
  $('input#exampleCustomerPhoneNumber').val(customer_info.phone_number);
  $('input#exampleCustomerEmail').val(customer_info.email);
});

$(function(){
  $('select.selectpicker-js').selectpicker();
  $('#order_products_form').on('cocoon:after-insert', function() {
    $('select.selectpicker-js').selectpicker();
  });

  function delivery_tracking_fields_hide_or_show_add_link() {
    if ($('#delivery_tracking_fields .nested-fields:visible').length >= 10) {
      $('#delivery_tracking_fields a.add_fields').hide();
    } else {
      $('#delivery_tracking_fields a.add_fields').show();
    }
  }

  $('#delivery_tracking_fields').on('cocoon:after-insert', function() {
    delivery_tracking_fields_hide_or_show_add_link();
  });
  $('#delivery_tracking_fields').on('cocoon:after-remove', function() {
    delivery_tracking_fields_hide_or_show_add_link();
  });
  delivery_tracking_fields_hide_or_show_add_link();

  function return_tracking_fields_hide_or_show_add_link() {
    if ($('#return_tracking_fields .nested-fields:visible').length >= 10) {
      $('#return_tracking_fields a.add_fields').hide();
    } else {
      $('#return_tracking_fields a.add_fields').show();
    }
  }

  $('#return_tracking_fields').on('cocoon:after-insert', function() {
    return_tracking_fields_hide_or_show_add_link();
  });

  $('#return_tracking_fields').on('cocoon:after-remove', function() {
    return_tracking_fields_hide_or_show_add_link();
  });
  return_tracking_fields_hide_or_show_add_link();
});
